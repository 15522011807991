import React from 'react';

export default function BannerPins() {
	return (
		<a
			href='https://pauatech-25525850.hubspotpagebuilder.eu/paua-pins'
			className='text-paua-deep-blue underline font-semibold whitespace-nowrap py-2.5 bg-paua-pink w-full hidden z-20 px-10 lg:px-32 top-14 sm:flex items-center justify-center gap-4'
			target='_blank'
			rel='noreferrer'
		>
			<div>
				{/* <p className='text-white text-sm py-2'>
				Paua Reimburse enables a business to fairly compensate your drivers at home, at
				work, or on the road. Learn more at our webinar on 22 <sup>nd</sup> March.
			</p> */}
				Learn more about Paua PINS, shared depots project.
			</div>
		</a>
	);
}
